import Vue from 'vue'
import VueRouter from 'vue-router'
import App from './App.vue'

Vue.use(VueRouter);
Vue.config.productionTip = false

import routes from './routes.js'

const router = new VueRouter({
	mode: 'history',
	routes,
	scrollBehavior(to, from, savedPosition) {
		if (to.hash) Vue.nextTick().then(() => document.querySelector(to.hash).scrollIntoView({behavior: 'smooth' }));
		else if (savedPosition) return savedPosition;
		else return { x: 0, y: 0 }
	},
});

router.beforeEach((to, from, next) => {

	var defaultTitle = "Let’s Bring Common Sense Back to B.C.";
	var defaultDescription = "Is it time for a new political alternative in British Columbia? We think so. Join our movement if you do too.";
	var defaultFacebookPicture = "facebook.jpg";
	var defaultTwitterPicture = "twitter.jpg";

	var newTitle = router.currentRoute.meta.socialTitle || defaultTitle;
	var newDescription = router.currentRoute.meta.socialDescription || defaultDescription;
	var newFacebookPicture = 'https://commonsensebc.com/' + (router.currentRoute.meta.socialImage || defaultFacebookPicture);
	var newTwitterPicture = 'https://commonsensebc.com/' + (router.currentRoute.meta.socialImage || defaultTwitterPicture);

	document.querySelector('title').innerText = newTitle;
	document.querySelector('meta[name="description"]').setAttribute('content', newDescription);
	document.querySelector('meta[name="twitter:title"]').setAttribute('content', newTitle);
	document.querySelector('meta[name="twitter:description"]').setAttribute('content', newDescription);
	document.querySelector('meta[name="twitter:image"]').setAttribute('content', newTwitterPicture);
	document.querySelector('meta[property="og:title"]').setAttribute('content', newTitle);
	document.querySelector('meta[property="og:description"]').setAttribute('content', newDescription);
	document.querySelector('meta[property="og:image"]').setAttribute('content', newFacebookPicture);

	next();
});

window.fbAsyncInit = function() {
	FB.init({ 
		appId: '262786994471808',
		status: true, 
		cookie: true, 
		xfbml: true,
		version: 'v9.0'
	});
};

(function() {
	console
	const initFacebookSDK = function(d, s, id) {
		var js, fjs = d.getElementsByTagName(s)[0];
		if (d.getElementById(id)) return;
		js = d.createElement(s); js.id = id;
		js.src = "https://connect.facebook.net/en_US/sdk.js";
		fjs.parentNode.insertBefore(js, fjs);
	}
	if(window.__PRERENDER_INJECTED && window.__PRERENDER_INJECTED.prerenderRunning) return;
	initFacebookSDK(document, 'script', 'facebook-jssdk');
})();

new Vue({
	router,
	render: h => h(App)
}).$mount('#app')
