<template>
<div id="app">
	<app-header></app-header>
	<router-view/>
	<app-footer></app-footer>
</div>
</template>

<script>
import AppHeader from '@/AppHeader.vue'
import AppFooter from '@/AppFooter.vue'
export default {
	mounted () {
	},
	components: {
		AppHeader, 
		AppFooter
	},
	data() {
		return {

		}
	},

	methods: {

	}

}
</script>

<style>
@import url('https://use.typekit.net/wln7vbi.css');

html { -ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%; font: 62.5%/1 sans-serif; }
body { margin:0; -moz-osx-font-smoothing: grayscale; -webkit-font-smoothing: antialiased; }
h1, h2, h3, h4, p, ol, ul { margin:0; padding:0; }
h1, h2, h3, h4 { font-size: inherit; font-weight: normal; }
a, button { color: inherit; }
a { text-decoration: none; }
img { vertical-align: top; max-width:100%; }
input, select, textarea, button { font: inherit; }
table { border-collapse: collapse; }

body { font: 1.8em "paralucent", sans-serif; position: relative; line-height: 1; }

.grey-box { background: #F8F8F8; color: #263957; }
.blue-box { background: #152339; color: white; }
.blue-box--light { background: #263957; color: white; }
.blue-box--transparent { position: relative; z-index: 1; }
.blue-box--transparent::after { background: rgba(38, 57, 87, 0.95); content: " "; z-index: -1; width: 100%; height: 100%; position: absolute; top: 0; }
.information-box { background: #BCC8E4; color: #263957; position: relative; padding: 50px; }
.information-box::before { content: "!"; width: 60px; height: 60px; background: #263957; color: white; position: absolute; border-radius: 50%; font-size: 4rem; display: flex; align-items: center; justify-content: center; top: -30px; left: -25px; }
.white-box { color: #263957; }

.wrapper { width: 92%; max-width: 1250px; margin: auto; }
.wrapper--spaced { padding: 80px 0; }
.wrapper--spaced-bottom { padding-bottom: 80px; }
.wrapper--centered { text-align: center; }
.wrapper--separator { border-bottom: 2px solid rgba(38, 57, 87, 0.62); }

.grecaptcha-badge { display: none; }

#video_player { display: none; position: fixed; z-index:9999; margin: auto; left: 0;right: 0; top: 0; bottom: 0; justify-content: center; align-items: center; }
#video_player::before { content: " "; z-index:-1; position: fixed; top:0; bottom:0; left:0; right:0; background: rgba(0,0,0,0.7); }
#video_player.video_player--open { display: flex; }

.button { background: transparent; appearance: none; outline:0; border:0; text-decoration: none; text-transform: uppercase; font-weight: bold; padding: 7px 15px; font-size: 1.8rem; cursor: pointer; display: inline-flex; align-items: center; justify-content: center; }
.button--yellow { background: #FFD542; color: #263957; }
.button--yellow:hover { background: #AAB7DA; }
.button--blue { background: #152339; color: white; }
.button--blue:hover { background:white; color: #062573; }
.button--large { padding: 14px 18px; }

.title { color: #062573; font-size: 3.5rem; text-transform: uppercase; font-weight: bold; line-height: 1; }
.title--white { color: white; }
.title--red { color: #E0231C; }
.title--small { font-size: 2.5rem; }
.title--normal-case { text-transform: none; }
.title--large { font-size: 4rem; }
.title--spaced { margin-bottom: 40px; }
.title--logo { display: flex; align-items: center; }
.title--logo img { height: 70px; margin-right: 20px; }

.text { padding-bottom: 20px; line-height: 1.5; font-size: 1.9rem; font-weight: normal; }
.text:last-child { padding-bottom: 0; }
.text--bold { font-weight: bold; }
.text--center { text-align: center; }

.link { color: rgb(192, 0, 0); font-weight: bold; line-height: 1; }
.link--blue { color: #062573; }
.link--uppercase { text-transform: uppercase; }
.link:hover { text-decoration: underline; }

.list { list-style: circle; display: flex; flex-direction: column; align-items: center; }

.cover { width: 100%; height: 700px; display: flex; background-repeat: no-repeat; background-size: cover; background-position: center 50%; }
.cover--medium { height: 450px; }
.cover--small { height: 325px; display: flex; align-items: flex-end; justify-content: center; }
.cover--image-top { background-position: center 5%; }
.cover--image-bottom { background-position: center 95%; }
.cover__content { display: flex; flex-direction: column; justify-content: center; }
.cover__content--top { margin-top: 60px; }
.cover__content--right { width: 50%; margin-left: auto; }
.cover__title { margin-bottom: 50px; font-size: 5rem; color: white; font-weight: bold; text-transform: uppercase; }
.cover--small .cover__title { text-shadow: 2px 2px 8px #000000a3; }
.cover--transparent { position: relative; z-index: 1; }
.cover--transparent::after { background: rgba(38, 57, 87, 0.95); content: " "; z-index: -1; width: 100%; height: 100%; position: absolute; top: 0; }
.cover__subtitle { margin-bottom: 10px; font-size: 3rem; font-weight: 500; color: white;  }

.jumbotron { background-size: cover; background-position: center; background-repeat: no-repeat; }
.jumbotron__content { }
.jumbotron__content--left { width: 30%; margin-top: 40px; }

.social { display: flex; margin: -20px 0 0 -20px; }
.social__item { width: 50px; padding: 20px 0 0 20px; text-decoration: none; color: #333; display: flex; align-items: center; font-weight: bold; justify-content: center; }
.social__item > * { width: 100%; }

.wide-image { width: 100%; height: 400px; object-fit: cover; margin: 40px 0; }

.form { background: #F3F1F1; padding: 50px; display: flex; flex-wrap: wrap; }
.form--inline { display: inline-flex; flex-wrap: wrap; flex-direction: column; }
.form--column { flex-direction: column; }
.form--boxless { background: none; padding:0; }
.form__item { width: 100%; display: flex; flex-direction: column; align-items: flex-start; margin-top: 20px; }
.form__item--duo { width: calc((100% - 20px) / 2); }
.form__item--duo + .form__item--duo:nth-child(2n) { margin-left: 20px; }
.form__item label:not(.segment__label):not(.option) { color: #9C9C9C; font-weight: 400; font-size: 1.5rem; margin-bottom: 5px; }
.form__item > input, .form__item > select, .form__item > textarea { width: 100%; box-sizing: border-box; padding: 7px 5px; }
.form__item > input, .form__item > textarea { border: 1px solid rgb(0, 0, 0, 0.20); border-radius: 2px; outline: none; }
.form__item--large > input, .form__item--large > select, .form__item--large > textarea { font-size: 2rem; padding: 14px 18px; border: 0; border-radius: 3px; box-sizing: border-box; }
.form__input--short { width: 160px; }

.share-buttons { margin: 15px 0 10px 0; display: flex; justify-content: center; }
.share-buttons > * { margin: 0 5px; }

.grid { width: 100%; display: flex; flex-wrap: wrap; align-items: flex-start; }
.grid--gutter { margin-left: -30px; width: calc(100% + 30px); }
.grid__tile { flex:1; box-sizing: border-box; position: relative; overflow: hidden; }
.grid--gutter .grid__tile { padding-left: 30px;  }
.grid__tile--third { flex: 33% 0 0; }
.grid__tile--black { background: rgba(0, 0, 0, 0.7); }
.grid__tile--red { background: rgba(171, 0, 0, 0.7); }
.grid__image { position: absolute; width: 100%; min-height: 100%; top: -9999px; bottom: -9999px; left: -9999px; right: -9999px; margin: auto; z-index: -1; }

	@media (max-width: 1100px) {
		.grid { flex-direction: column; }
		.cover__content--right { width: 100%; }
		.grid--gutter { margin-left: 0; margin-top: -30px; width: 100%; }
		.grid--gutter .grid__tile { padding-left: 0; padding-top: 30px; }
	}

	@media (max-width: 768px) {
		.cover { height: auto; min-height: 300px; padding: 40px 7.5%; padding-bottom: 20px; box-sizing: border-box; background-position: center; }
		.cover--home { background-position-y: top; background-position-x: 80%; text-align: center; position: relative; z-index: 0; }
		.cover--home::before { content: " "; width:100%; height: 100%; background: linear-gradient(to top, transparent, #263957); position: absolute; top: 0; left: 0; z-index: -1; }
		.form:not(.form--boxless) { padding: 40px 15px; }
		.form__item { width: 100%; flex-direction: column; }
		.form__item > input, .form__item > select, .form__item > textarea { width: 100%; box-sizing: border-box; border: 1px solid #cccccc; border-radius: 2px; padding: 7px 5px; outline: none; margin:0; }
		.form__item--duo + .form__item--duo:nth-child(2n) { margin-left: 0; }

		.jumbotron__content--left { width: 100%; }
	}

	
</style>
