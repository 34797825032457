<template>
  <footer class="footer">
     <div class="wrapper footer__wrapper">
        <a href="/home"><img class="footer__logo" src="@/assets/logo-horizontal.svg" alt=""/></a>
         <ul class="footer__nav">
            <li><a class="footer__item" href="/about">About</a></li>
            <li><a class="footer__item" href="/join">join</a></li>
            <li><a class="footer__item" href="/contact">Contact</a></li>
         </ul>
         <p>&copy; {{ (new Date()).getFullYear() }} Common Sense BC<br>All Rights Reserved.</p>
     </div>
  </footer>
</template>

<script>
export default {
  created() {

  },
  data () {
    return {

    }
  },
  computed: {

  },
  methods: {

  }
}
</script>

<style scoped>

.footer { width: 100%; display: flex; align-items: center; background: #152339; color: #7282AA; font-weight: 600; padding: 60px 0; }
.footer__wrapper { display: flex; flex-direction: column; align-items: center; }
.footer__logo { height: 30px; margin-bottom: 20px; }  
.footer__nav { padding: 30px 0; display: flex; list-style: none; text-transform: uppercase; }
.footer__item { padding: 0 5px; }

@media (max-width: 1100px) {

}

@media (max-width: 768px) {

}

</style>
